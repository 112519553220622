import { Component, OnInit } from '@angular/core';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';
import $ from 'jquery';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NATIONS } from '../data/nation';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  httpUrl = this.endpoints.customerUrl;
  pagination = 1;
  dataSource = [];
  filterCol = 'Fullname'
  Nations = NATIONS;
  filterSearch = '';
  loading = false;
  isLoading = false;
  buttonAttr = "Create";
  editId;

  customerForm = new FormGroup({
    fullname: new FormControl('', Validators.required),
    email: new FormControl("", Validators.compose([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(1)])),
    address: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
    state: new FormControl(''),
    country: new FormControl(''),
    town: new FormControl(''),
  });


  constructor(
    private endpoints: EndpointsService,
    private genServ: GeneralService,
  ) { }

  ngOnInit() {
    this.handleCustomersList()
  }

  private handleCustomersList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrl).subscribe((result: any) => {
      // console.log(result.users, 'choice');
      const { users } = result;
      this.dataSource = users;
      this.loading = false;
    });
  }

  handleEdit(data) {
    this.buttonAttr = 'Update';
    this.editId = data.id;
    this.customerForm.patchValue({
      fullname: data.fullname,
      email: data.email,
      address: data.address,
      mobile: data.mobile,
      state: data.state,
      town: data.town,
      country: data.country,
    });
  }

  handleUpdateCustomer() {
    this.isLoading = true;
    this.endpoints.updateDetails(this.httpUrl, this.editId, this.customerForm.value).subscribe(
      (result: any) => {
        if (result) {
          $("#newCustomerModal .close").click();
          // console.log(result, "response");
          this.customerForm.reset();
          this.genServ.sweetAlertSuccess(result.message);
          this.handleCustomersList();
          this.buttonAttr = 'Create';
          this.editId = '';
        }
        this.isLoading = false;
      },
    ), error => {
      this.isLoading = false;
    };
  }

  handleDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Customer").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
          this.handleCustomersList();
        });
      }
    })
  }

}
