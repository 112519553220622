export const exportTableToCSV = (
    data: any[],
    columns: { value: string; title: string }[],
    filename
) => {
    let newData = [];
    data.forEach((dat) => {
        newData.push(columns.map((col) => `"${dat[col.value]}"`).join(","));
    });
    newData = [columns.map((col) => col.title).join(","), ...newData];
    downloadCSV(newData.join("\n"), `${filename}.csv`);
};

export const downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;
    // CSV file
    csvFile = new Blob([csv], { type: "text/csv" });
    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
};