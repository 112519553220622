import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from '../auth/auth.service';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';
import { LocalStorageService } from '../utils/LocalStorage';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  loggedInUser: any;
  viewType = this.router.url.slice(11)
  loading = false;


  constructor(
    private localstorage: LocalStorageService,
    private authServ: AuthService,
    private genServ: GeneralService,
    private router: Router,
  ) {
    this.loggedInUser = JSON.parse(
      this.localstorage.getFromLocalStorage('AdminUserInfo')
    );
    // check token validation
    this.genServ.expiredToken.subscribe(res => {
      if (res) {
        Swal.fire({
          type: 'success',
          position: 'top-end',
          title: 'Your Session Has Expired!. Login!',
          toast: true,
          showConfirmButton: false,
          timer: 9000,
        });
      }
    });
  }

  ngOnInit() {

  }

  handleNavigation() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.router.navigate([`/dashboard/${this.viewType}`])
    }, 1000);
  }

  handleLogout() {
    this.authServ.logout();
  }

}
