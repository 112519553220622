import { Component, OnInit } from '@angular/core';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';
import $ from 'jquery';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  httpUrl = this.endpoints.productUrl;
  httpUrlCat = this.endpoints.categoryUrl;
  httpUrlOtherProductImgs = this.endpoints.otherProductImgUrls;
  pagination = 1;
  dataSource = [];
  filterCol = 'Name'
  filterSearch = '';
  loading = false;
  categories = [];
  imgURL;
  formData = new FormData();
  imageSizeMaxed = false;
  buttonAttr = 'Create';
  editId;
  isLoading = false;
  hidImgSpinner = true;

  viewpage = '';
  toggle = false;
  selectedCategory = "original"
  skuDisabledOnEdit = false;
  selectedProductImagesUrls = [];

  productForm = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    sku: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    weigh: new FormControl(''),
    material: new FormControl(''),
    condition: new FormControl(''),
    explore: new FormControl(false),
    bestSellers: new FormControl(false),
    soldTag: new FormControl(false),
    sizePriceFormArray: this.fb.array([]),
  });

  categoryForm = new FormGroup({
    name: new FormControl('', Validators.required),
  });


  constructor(
    private endpoints: EndpointsService,
    private fb: FormBuilder,
    private genServ: GeneralService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe(res => {
      const { viewType } = res;
      // this.filterCol = viewType ? 'Fullname' : 'Event-Name';
      this.toggle = viewType ? true : false;
      this.viewpage = viewType;
    })
  }

  ngOnInit() {
    this.handleProductListByCategory();
    this.handleCategoriesList();
  }

  get sizePriceFormArray(): FormArray {
    return this.productForm.get("sizePriceFormArray") as FormArray;
  }


  handleProductListByCategory() {
    this.loading = true;
    this.endpoints.fetchAll(`${this.httpUrl}/category/${this.selectedCategory}`).subscribe((result: any) => {
      // console.log(result.products, 'Product');
      const { products } = result;
      this.dataSource = products;
      this.loading = false;
    });
  }

  private handleCategoriesList() {
    this.endpoints.fetchAll(this.httpUrlCat).subscribe((result: any) => {
      // console.log(result.categories, 'categories');
      const { categories } = result;
      this.categories = categories;
    });
  }

  // Get Size Info
  private get getSizePriceDetails() {
    const returnSizePrice = this.sizePriceFormArray.value.map((element, i) => {
      return {
        dimension: element.dimension,
        price: element.price,
        default: element.default,
      };
    });
    return returnSizePrice;
  }

  private handleFormDataAppend() {
    this.productForm.value.name ? this.formData.set("name", this.productForm.value.name) : null;
    this.productForm.value.description ? this.formData.set("description", this.productForm.value.description) : null;
    this.productForm.value.sku ? this.formData.set("sku", this.productForm.value.sku) : null;
    this.productForm.value.category ? this.formData.set("category", this.productForm.value.category) : null;
    this.getSizePriceDetails.length > 0 ? this.formData.set("sizes_prices", JSON.stringify(this.getSizePriceDetails)) : null;
    this.productForm.value.weigh ? this.formData.set("weigh", this.productForm.value.weigh) : null;
    this.productForm.value.material ? this.formData.set("material", this.productForm.value.material) : null;
    this.productForm.value.condition ? this.formData.set("condition", this.productForm.value.condition) : null;
    this.formData.set("explore", this.productForm.value.explore || false);
    this.formData.set("bestSellers", this.productForm.value.bestSellers || false);
    this.formData.set("soldTag", this.productForm.value.soldTag || false);
  }

  handleViewChange() {
    !this.toggle ? this.router.navigate(['/dashboard/products']) : this.router.navigate(['/dashboard/products'], {
      queryParams: { viewType: 'categories' },
    })
  }

  handleNewCategory() {
    this.isLoading = true;
    this.endpoints.create(this.httpUrlCat, this.categoryForm.value).subscribe((result: any) => {
      if (result) {
        this.handleCategoriesList();
        this.categoryForm.reset();
        $("#newCategoryModal .close").click();
        this.genServ.sweetAlertSuccess(result.message);
      }
      this.isLoading = false;
    }), error => {
      this.isLoading = false;
    };
  }


  handleNewProduct() {
    this.isLoading = true;
    this.handleFormDataAppend();
    // this.formData.forEach(res => console.log(res, 'ko'));
    this.genServ.sweetAlertCreate("Product").then(response => {
      if (response.value) {
        this.endpoints.create(this.httpUrl, this.formData).subscribe(
          (result: any) => {
            if (result) {
              $("#newProductModal .close").click();
              // console.log(result, "response");
              this.productForm.reset();
              this.imgURL = undefined;
              this.genServ.sweetAlertSuccess(result.message);
              this.handleProductListByCategory();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      }
    });
  }

  addSizePrice() {
    this.sizePriceFormArray.push(
      this.fb.group({
        dimension: ["", Validators.required],
        default: [0, Validators.required],
        price: [0, Validators.required],
      })
    );
  }

  // Remove a form field for product sizes
  deleteSizePrice(index: number) {
    this.sizePriceFormArray.removeAt(index);
  }

  createFormArray() {
    this.sizePriceFormArray.push(
      this.fb.group({
        dimension: ["", Validators.required],
        default: [0, Validators.required],
        price: [0, Validators.required],
      })
    );
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  onFileUpload(e) {
    const files = <File>e.target.files[0];
    this.formData.set("files", files);
    // console.log(files, 'files');
    this.imageSizeMaxed = files.size > 1000000;
    var reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      // console.log(this.imgURL, 'localurj');
    };
  }

  handleEdit(data) {
    this.clearFormArray(this.sizePriceFormArray)
    this.skuDisabledOnEdit = true;
    this.hidImgSpinner = false;
    this.buttonAttr = 'Update';
    this.editId = data.id;
    this.formData.delete('files');
    this.productForm.patchValue({
      name: data.name,
      description: data.description,
      sku: data.sku,
      category: data.category,
      weigh: data.weigh,
      material: data.material,
      condition: data.condition,
      explore: data.explore,
      bestSellers: data.bestSellers,
      soldTag: data.soldTag,
    });

    const sizes_prices = JSON.parse(data.sizes_prices);
    // console.log(sizes_prices, 'eod')
    setTimeout(() => {
      sizes_prices.forEach((element) => {
        this.sizePriceFormArray.push(
          this.fb.group({
            dimension: [element.dimension, Validators.required],
            price: [element.price, Validators.required],
            default: [element.default, Validators.required],
          })
        );
      });
    }, 2000);

    this.imgURL = `https://api.kokopelliafrica.com/kokopelli-backend/uploads/${data.imageUrl}`;
    setTimeout(() => {
      this.hidImgSpinner = true;
    }, 4000);
  }

  handleArtGalleryBox(artGData: any, type: string): boolean {
    if (artGData) {
      const arr = artGData.split(',')
      return arr.includes(type)
    }
    return false;
  }

  handleImagesByProduct(data) {
    this.editId = data.id;
    // console.log(JSON.parse(data.img_urls), 'imagsURL')
    this.selectedProductImagesUrls = data.otherImgUrls ? JSON.parse(data.otherImgUrls) : [];
  }

  handleNewProductImage() {
    this.isLoading = true;
    this.genServ.sweetAlertCreate("Product Image").then(response => {
      if (response.value) {
        this.endpoints.create(`${this.httpUrlOtherProductImgs}/${this.editId}`, this.formData).subscribe(
          (result: any) => {
            if (result) {
              $("#newProductImagesModal .close").click();
              // console.log(result, "response");
              this.genServ.sweetAlertSuccess(result.message);
              this.handleProductListByCategory();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      } else {
        this.isLoading = false;
      }
    });
  }

  handleDeleteProductImage(imgUrl) {
    this.genServ.sweetAlertFileDeletions("Product Image").then(response => {
      if (response.value) {
        this.endpoints.create(`${this.httpUrlOtherProductImgs}/${this.editId}/${imgUrl}`, this.formData).subscribe(
          (result: any) => {
            if (result) {
              $("#newProductImagesModal .close").click();
              // console.log(result, "response");
              this.genServ.sweetAlertSuccess('Image Deleted');
              this.handleProductListByCategory();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      } else {
        this.isLoading = false;
      }
    });
  }

  handleUpdateProduct() {
    this.isLoading = true;
    this.handleFormDataAppend();
    this.endpoints.updateDetails(this.httpUrl, this.editId, this.formData).subscribe(
      (result: any) => {
        if (result) {
          $("#newProductModal .close").click();
          // console.log(result, "response");
          this.productForm.reset();
          this.genServ.sweetAlertSuccess(result.message);
          this.handleProductListByCategory();
          this.buttonAttr = 'Create';
          this.editId = '';
          this.imgURL = undefined;
        }
        this.isLoading = false;
      },
    ), error => {
      this.isLoading = false;
    };

  }

  handleDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Product").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
          this.handleProductListByCategory();
        });
      }
    })
  }

  handleCategoryDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Category").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrlCat, id).subscribe((result: any) => {
          this.handleCategoriesList();
        });
      }
    })
  }
}
