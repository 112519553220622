import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ValidationService } from 'src/app/shared/validation.service';
import { EndpointsService } from 'src/app/shared/endpoints.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/utils/LocalStorage';
import { GeneralService } from 'src/app/shared/general.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  view = false;
  username: string;
  password: string;
  errMsg: any = {};
  loggingIn = false;
  isLoading = false;

  constructor(
    private validation: ValidationService,
    private endpoints: EndpointsService,
    private router: Router,
    private localstorage: LocalStorageService,
    private authService: AuthService
  ) {
    $.getScript('../../assets/js/tweenMax.js', (data, textStatus) => {
      if (textStatus === 'success') {
        this.view = true;
      }
    });
  }

  ngOnInit() { }

  handleLogin() {
    this.isLoading = true;
    this.validateForm();
    if ($.isEmptyObject(this.errMsg)) {
      this.loggingIn = false;
      this.endpoints.loginAdmin(this.loginDetails()).subscribe(
        (res: any) => {
          if (res) {
            const { data: { token } } = res;
            this.endpoints.tokenGenerated.next(token);
            this.localstorage
              .saveToLocalStorage('AdminUsertoken', token)
              .subscribe(res2 => {
                if (res2) {
                  const redirect = this.authService.redirectUrl
                    ? this.authService.redirectUrl
                    : '/admin-selection';
                  this.router.navigate([`${redirect}`]);
                }
              });
          }
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.loggingIn = false;
          const {
            error: {
              error: { message },
            },
          } = error;
          console.log(message, 'ErrMsg');
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: `${message}`,
          });
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  validateForm() {
    this.errMsg = this.validation.loginValidator(this.loginDetails());
  }

  loginDetails() {
    return {
      username: this.username,
      password: this.password,
    };
  }
}
