import { Component, OnInit } from '@angular/core';
import { EndpointsService } from '../shared/endpoints.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from '../shared/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';


@Component({
  selector: 'app-banner-carousel',
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['./banner-carousel.component.css']
})
export class BannerCarouselComponent implements OnInit {

  httpUrl = this.endpoints.bannerCarouselUrl;
  pagination = 1;
  dataSource = [];
  loading = false;
  formData = new FormData();
  editId;
  isLoading = false;
  hidImgSpinner = true;
  selectedBannerCarouselImagesUrls = [];
  imgURL = [];


  bannerForm = new FormGroup({
    name: new FormControl('', Validators.required),
  });


  constructor(
    private endpoints: EndpointsService,
    private genServ: GeneralService,
  ) { }

  ngOnInit() {
    this.handleBannerList();
  }

  private handleBannerList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrl).subscribe((result: any) => {
      const { data } = result;
      this.dataSource = data;
      this.loading = false;
    });
  }

  private handleFormDataAppend() {
    this.bannerForm.value.name ? this.formData.set("name", this.bannerForm.value.name) : null;
  }


  handleNewBannerCarousel() {
    this.isLoading = true;
    this.handleFormDataAppend();
    this.genServ.sweetAlertCreate("Banner/Carousel").then(response => {
      if (response.value) {
        this.endpoints.create(this.httpUrl, this.formData).subscribe(
          (result: any) => {
            if (result) {
              $("#newBannerCarouselModal .close").click();
              this.genServ.sweetAlertSuccess(result.message);
              this.handleBannerList();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      } else {
        this.isLoading = false;
      }
    });
  }

  handleImagesByBannerCarousel(data) {
    this.handleFormReset()
    this.editId = data.id;
    this.selectedBannerCarouselImagesUrls = data.img_urls ? JSON.parse(data.img_urls) : [];
  }

  onMultiFileUpload(e) {
    const files: File[] = e.target.files;

    for (let file of files) {
      if (file.type == "application/pdf") {
        return;
      }
      if (file.size > 800000) {
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imgURL.push(reader.result);
      };
      this.formData.append('files', file);
    }

  }

  handleNewBannerCarouselImage() {
    this.isLoading = true;
    this.endpoints.updateDetails(this.httpUrl, this.editId, this.formData).subscribe(
      (result: any) => {
        if (result) {
          $("#newBannerCarouselImagesModal .close").click();
          this.genServ.sweetAlertSuccess(result.message);
          this.handleBannerList();
        }
        this.isLoading = false;
      },
    ), error => {
      this.isLoading = false;
    };

  }

  handleDeleteBannerCarouselImage(imgUrl) {
    this.genServ.sweetAlertFileDeletions("Image").then(response => {
      if (response.value) {
        this.endpoints.create(`${this.httpUrl}/images/${this.editId}/${imgUrl}`, this.formData).subscribe(
          (result: any) => {
            if (result) {
              $("#newBannerCarouselImagesModal .close").click();
              this.genServ.sweetAlertSuccess('Image Deleted');
              this.handleBannerList();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      } else {
        this.isLoading = false;
      }
    });
  }

  handleFormReset() {
    this.bannerForm.reset();
    this.imgURL = [];
    this.formData = new FormData();
  }

  handleDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Banner/Carousel").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
          this.handleBannerList();
        });
      }
    })
  }

}
