import { Component, OnInit } from '@angular/core';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';
import $ from 'jquery';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  httpUrl = this.endpoints.portfolioUrl;
  httpUrlDomain = this.endpoints.portfolioDomainUrl;
  httpUrlProduct = this.endpoints.productUrl;
  httpUrlImg = this.endpoints.portfolioImgUrl;
  pagination = 1;
  dataSource = [];
  dataSourceProducts = [];
  filterCol = 'Name'
  filterSearch = '';
  loading = false;
  formData = new FormData();
  formData2 = new FormData();
  buttonAttr = 'Create';
  editId;
  isLoading = false;
  hidImgSpinner = true;
  selectedPortfolioImagesUrls = [];
  imgURL;
  src
  imageSizeMaxed = false;
  previewDoc = false;
  domainCategories = {
    kokopelli:
      [
        { name: 'Art Exhibitions', value: 'artExhibition' },
        { name: 'Art Fairs', value: 'artFair' },
        { name: 'Events', value: 'events' },
        { name: 'Catalogs', value: 'kokopelli-pdfCatalogs' },
      ]
    ,
    vogoandnovo: [
      { name: 'Interior Projects', value: 'interiorProject' },
      { name: ' Vogo Foundation', value: 'vogoFoundation' },
      { name: 'Custom Artworks ', value: 'customArtwork' },
      { name: 'PDF Catalogs', value: 'vogo-pdfCatalogs' },
    ]
  };
  dropDownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    closeDropDownOnSelection: false,
    allowSearchFilter: true,
    enableCheckAll: false
  }
  view = "kokopelli";
  toggle = false;

  portfolioForm = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    author: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    fromDate: new FormControl(0, Validators.required),
    toDate: new FormControl(0, Validators.required),
    domain: new FormControl('', Validators.required),
    shopItemIDs: new FormControl('', Validators.required)
  });


  constructor(
    private endpoints: EndpointsService,
    private fb: FormBuilder,
    private genServ: GeneralService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe(res => {
      const { viewType } = res;
      if (viewType === 'vogoandnovo') {
        this.toggle = true;
        this.view = viewType;
      } else {
        this.toggle = false;
        this.view = 'kokopelli';
      }

      this.handlePortfolioList();
      this.handleProductList();
    })
  }

  ngOnInit() {
  }

  private handlePortfolioList() {
    this.loading = true;
    this.endpoints.fetchOne(this.httpUrlDomain, this.view).subscribe((result: any) => {
      // console.log(result.portfolios, 'Portfolio');
      const { portfolios } = result;
      this.dataSource = portfolios;
      this.loading = false;
    });
  }

  private handleProductList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrlProduct).subscribe((result: any) => {
      const { products } = result;
      // console.log(products, 'products');
      this.dataSourceProducts = products;
      this.loading = false;
    });
  }

  private handleFormDataAppend() {
    let project_date;
    if (this.portfolioForm.value.fromDate && this.portfolioForm.value.toDate) {
      project_date = new Date(Date.parse((this.portfolioForm.value.fromDate))).setHours(0, 0, 0, 0).toString() + '_' + new Date(Date.parse((this.portfolioForm.value.toDate))).setHours(23, 59, 59, 59).toString();

    }
    this.portfolioForm.value.name ? this.formData2.set("name", this.portfolioForm.value.name) : null;
    this.portfolioForm.value.description ? this.formData2.set("description", this.portfolioForm.value.description) : null;
    this.portfolioForm.value.author ? this.formData2.set("author", this.portfolioForm.value.author) : null;
    this.formData2.set("project_date", project_date);
    this.portfolioForm.value.category ? this.formData2.set("category", this.portfolioForm.value.category) : null;
    this.portfolioForm.value.domain ? this.formData2.set("domain", this.portfolioForm.value.domain) : null;
    this.portfolioForm.value.shopItemIDs ? this.formData2.set("shopItemIDs", this.extractShopIDS(this.portfolioForm.value.shopItemIDs)) : null;
  }

  extractShopIDS(shopIDS: { name: string, id: string }[]): string {
    return JSON.stringify(shopIDS.map(el => el.id))
  }

  handleNewPortfolio() {
    this.isLoading = true;
    this.handleFormDataAppend();
    this.genServ.sweetAlertCreate("Portfolio").then(response => {
      if (response.value) {
        this.endpoints.create(this.httpUrl, this.formData2).subscribe(
          (result: any) => {
            if (result) {
              $("#newPortfolioModal .close").click();
              // console.log(result, "response");
              this.portfolioForm.reset();
              this.genServ.sweetAlertSuccess(result.message);
              this.handlePortfolioList();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      } else {
        this.isLoading = false;
      }
    });
  }

  handleNewPortfolioImage() {
    this.isLoading = true;
    this.genServ.sweetAlertCreate("Portfolio Image").then(response => {
      if (response.value) {
        this.endpoints.create(`${this.httpUrlImg}/${this.editId}`, this.formData).subscribe(
          (result: any) => {
            if (result) {
              $("#newPortfolioImagesModal .close").click();
              // console.log(result, "response");
              this.portfolioForm.reset();
              this.genServ.sweetAlertSuccess(result.message);
              this.handlePortfolioList();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      } else {
        this.isLoading = false;
      }
    });
  }


  handleEdit(data) {
    this.hidImgSpinner = false;
    this.buttonAttr = 'Update';
    this.editId = data.id;
    this.portfolioForm.patchValue({
      name: data.name,
      description: data.description,
      author: data.author,
      category: data.category,
      fromDate: this.genServ.getDateformat(Number(data.project_date.split('_')[0])),
      toDate: this.genServ.getDateformat(Number(data.project_date.split('_')[1])),
      domain: data.domain,
      shopItemIDs: JSON.parse(data.shopItemIDs)
    });
    this.imgURL = `https://api.kokopelliafrica.com/kokopelli-backend/uploads/portfolio/${data.coverImg}`;
    setTimeout(() => {
      this.hidImgSpinner = true;
    }, 4000);
  }

  handleImagesByPortfolio(data) {
    this.editId = data.id;
    // console.log(JSON.parse(data.img_urls), 'imagsURL')
    this.selectedPortfolioImagesUrls = data.img_urls ? JSON.parse(data.img_urls) : [];
  }

  onFileUpload(e) {
    const files = <File>e.target.files[0];
    this.formData.set("img", files);
    this.imageSizeMaxed = files.size > 800000;
    if (this.imageSizeMaxed) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  onFileUpload2(e) {
    const files = <File>e.target.files[0];
    if (files.type === "application/pdf") {
      return;
    }
    this.imageSizeMaxed = files.size > 800000;
    if (this.imageSizeMaxed) {
      return;
    }
    this.formData2.set("files", files);
    var reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  onFileSelected(e) {
    this.previewDoc = false;
    this.imgURL = '';
    const file = <File>e.target.files[0];
    if (file.type !== "application/pdf") {
      return;
    }
    this.imageSizeMaxed = file.size > 52428800;
    if (this.imageSizeMaxed) {
      return;
    }
    this.formData2.set("files", file);
    var pdfjsLib = window['pdfjs-dist/build/pdf'];
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://mozilla.github.io/pdf.js/build/pdf.worker.js';
      if (file.type === "application/pdf") {
        var fileReader = new FileReader();
        fileReader.onload = function (e:any) {
          var pdfData = new Uint8Array(e.currentTarget.result);
          // Using DocumentInitParameters object to load binary data.
          var loadingTask = pdfjsLib.getDocument({ data: pdfData });
          loadingTask.promise.then(function (pdf) {
            console.log('PDF loaded');

            // Fetch the first page
            var pageNumber = 1;
            pdf.getPage(pageNumber).then(function (page) {
              console.log('Page loaded');

              var scale = 1.5;
              var viewport = page.getViewport({ scale: scale });

              // Prepare canvas using PDF page dimensions
              var canvas = $("#pdfViewer")[0];
              var context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              var renderContext = {
                canvasContext: context,
                viewport: viewport
              };
              var renderTask = page.render(renderContext);
              renderTask.promise.then(function () {
                console.log('Page rendered');
              });
            });
          }, function (reason) {
            // PDF loading error
            console.error(reason);
          });
        };
        fileReader.readAsArrayBuffer(file);
        this.previewDoc = true;
      }
  }

  handleUpdatePortfolio() {
    this.isLoading = true;
    this.handleFormDataAppend();
    this.endpoints.updateDetails(this.httpUrl, this.editId, this.formData2).subscribe(
      (result: any) => {
        if (result) {
          $("#newPortfolioModal .close").click();
          // console.log(result, "response");
          this.portfolioForm.reset();
          this.genServ.sweetAlertSuccess(result.message);
          this.handlePortfolioList();
          this.buttonAttr = 'Create';
          this.editId = '';
        }
        this.isLoading = false;
      },
    ), error => {
      this.isLoading = false;
    };

  }

  handleDeletePortfolioImage(imgUrl) {
    this.genServ.sweetAlertFileDeletions("Portfolio Image").then(response => {
      if (response.value) {
        this.endpoints.create(`${this.httpUrlImg}/${this.editId}/${imgUrl}`, this.formData).subscribe(
          (result: any) => {
            if (result) {
              $("#newPortfolioImagesModal .close").click();
              // console.log(result, "response");
              this.genServ.sweetAlertSuccess('Image Deleted');
              this.handlePortfolioList();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      } else {
        this.isLoading = false;
      }
    });
  }

  handleViewChange() {
    !this.toggle ? this.router.navigate(['/dashboard/portfolio'], {
      queryParams: { viewType: 'kokopelli' },
    }) : this.router.navigate(['/dashboard/portfolio'], {
      queryParams: { viewType: 'vogoandnovo' },
    })
  }


  handleDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Portfolio").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
          this.handlePortfolioList();
        });
      }
    })
  }

}
