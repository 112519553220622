import jwt from 'jsonwebtoken';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GeneralService } from '../shared/general.service';

@Injectable({
  providedIn: 'root',
})
export class TokenVerification {
  secretOrPrivateKey = 'passkey';

  constructor(private genSer: GeneralService) { }

  verifyToken(token) {
    return jwt.verify(token, this.secretOrPrivateKey, (error, decoded) => {
      if (error) {
        this.genSer.expiredToken.next(error);
        return false;
      }
      if (Object.keys(decoded).length !== 4) {
        return false;
      }
      this.genSer.expiredToken.next('');
      return true;
    });
  }
}
