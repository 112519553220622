import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ValidationService } from 'src/app/shared/validation.service';
import { EndpointsService } from 'src/app/shared/endpoints.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit {
  view = false;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  errMsg: Array<any>;
  submiting = false;

  constructor(
    private validation: ValidationService,
    private endpoints: EndpointsService
  ) {
    $.getScript('../../assets/js/tweenMax.js', (data, textStatus) => {
      if (textStatus === 'success') {
        this.view = true;
      }
    });
  }

  ngOnInit() {}

  handleSubmit() {
    this.validateForm();
    if ($.isEmptyObject(this.errMsg)) {
      this.submiting = true;
      this.endpoints.registerAdmin(this.signInDetails()).subscribe(
        (res: any) => {
          if (res) {
            this.submiting = false;
            if (res.data.username === 'motorAdmin') {
              Swal.fire({
                type: 'success',
                title: 'You have been register!',
              });
            } else {
              Swal.fire({
                type: 'success',
                title: 'You have been register!',
                text:
                  'Your confirmation email will be sent to you once you have been approved by the Administrator',
              });
            }
          } else {
            this.submiting = false;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong! Try again',
            });
          }
        },
        error => {
          this.submiting = false;
          const {
            error: {
              error: { message },
            },
          } = error;
          console.log(message, '--Error Message');
          if (message.includes('already in use')) {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: `${message}`,
            });
          } else {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong! Try again',
            });
          }
        }
      );
    }
  }

  validateForm() {
    this.errMsg = this.validation.signupValidator(this.signInDetails());
  }

  signInDetails() {
    return {
      username: this.username,
      email: this.email,
      password: this.password,
      confirmPassword: this.confirmPassword,
      isAdmin: false,
    };
  }
}
