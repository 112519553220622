import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {

  httpUrl = this.endpoints.teamsUrl;
  pagination = 1;
  dataSource = [];
  loading = false;
  filterCol = 'Name'
  filterSearch = '';
  isLoading = false;
  buttonAttr = 'Create';
  editId;
  hidImgSpinner = true;
  imgURL;
  imageSizeMaxed = false;
  previewDoc = false;
  formData = new FormData();

  teamForm = new FormGroup({
    name: new FormControl('', Validators.required),
    designation: new FormControl(" ", Validators.required),
    position: new FormControl(1, Validators.required),
    bio: new FormControl(''),
  });

  constructor(private endpoints: EndpointsService, private genServ: GeneralService  ) {
  }

  ngOnInit() {
    this.handleTeamList()
  }

  handleTeamList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrl).subscribe((result: any) => {
      const { team } = result;
      this.dataSource = team;
      this.loading = false;
    });
  }

  private handleFormDataAppend() {
    this.teamForm.value.name ? this.formData.set("name", this.teamForm.value.name) : null;
    this.teamForm.value.position ? this.formData.set("position", this.teamForm.value.position) : null;
    this.teamForm.value.designation ? this.formData.set("designation", this.teamForm.value.designation) : null;
    this.teamForm.value.bio ? this.formData.set("bio", this.teamForm.value.bio) : null;
  }

  handleNewTeamMember() {
    this.isLoading = true;
    this.handleFormDataAppend();

    this.genServ.sweetAlertCreate("Team Member").then(response => {
      if (response.value) {
        this.endpoints.create(this.httpUrl, this.formData).subscribe(
          (result: any) => {
            if (result) {
              $("#newTeamModal .close").click();
              this.teamForm.reset();
              this.genServ.sweetAlertSuccess(result.message);
              this.handleTeamList();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      } else {
        this.isLoading = false;
      }
    });
  }

  handleEdit(data) {
    this.hidImgSpinner = false;
    this.buttonAttr = 'Update';
    this.editId = data.id;
    this.teamForm.patchValue({
      name: data.name,
      designation: data.designation,
      position: data.position,
      bio: data.bio,
    });
    this.imgURL = `https://api.kokopelliafrica.com/kokopelli-backend/uploads/teams/${data.imgUrl}`;
    setTimeout(() => {
      this.hidImgSpinner = true;
    }, 4000);
  }

  onFileUpload(e) {
    const files = <File>e.target.files[0];
    this.formData.set("files", files);
    this.imageSizeMaxed = files.size > 800000;
    if (this.imageSizeMaxed) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  onFileSelected(e) {
    this.previewDoc = false;
    this.imgURL = '';
    const file = <File>e.target.files[0];
    if (file.type !== "application/pdf") {
      return;
    }
    this.imageSizeMaxed = file.size > 800000;
    if (this.imageSizeMaxed) {
      return;
    }
    this.formData.set("files", file);
    var pdfjsLib = window['pdfjs-dist/build/pdf'];
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://mozilla.github.io/pdf.js/build/pdf.worker.js';
    if (file.type === "application/pdf") {
      var fileReader = new FileReader();
      fileReader.onload = function (e: any) {
        var pdfData = new Uint8Array(e.currentTarget.result);
        // Using DocumentInitParameters object to load binary data.
        var loadingTask = pdfjsLib.getDocument({ data: pdfData });
        loadingTask.promise.then(function (pdf) {
          console.log('PDF loaded');

          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(function (page) {
            console.log('Page loaded');

            var scale = 1.5;
            var viewport = page.getViewport({ scale: scale });

            // Prepare canvas using PDF page dimensions
            var canvas = $("#pdfViewer")[0];
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              console.log('Page rendered');
            });
          });
        }, function (reason) {
          // PDF loading error
          console.error(reason);
        });
      };
      fileReader.readAsArrayBuffer(file);
      this.previewDoc = true;
    }
  }

  handleUpdateTeam() {
    this.isLoading = true;
    this.handleFormDataAppend();

    this.endpoints.updateDetails(this.httpUrl, this.editId, this.formData).subscribe(
      (result: any) => {
        if (result) {
          $("#newTeamModal .close").click();
          this.teamForm.reset();
          this.genServ.sweetAlertSuccess(result.message);
          this.handleTeamList();
          this.buttonAttr = 'Create';
          this.editId = '';
        }
        this.isLoading = false;
      },
    ), error => {
      this.isLoading = false;
    };

  }

  handleTeamDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Member").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
          this.handleTeamList();
        });
      }
    })
  }
}
