 $(document).ready(function () {
   'use strict';

   $('#wizard1').steps({
     headerTag: 'h3',
     bodyTag: 'section',
     autoFocus: true,
     titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>'
   });

   $('#wizard2').steps({
     headerTag: 'h3',
     bodyTag: 'section',
     autoFocus: true,
     titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
     onStepChanging: function (event, currentIndex, newIndex) {
       if (currentIndex < newIndex) {
         // Step 1 form validation
         if (currentIndex === 0) {
           var fname = $('#firstname').parsley();
           var lname = $('#lastname').parsley();

           if (fname.isValid() && lname.isValid()) {
             return true;
           } else {
             fname.validate();
             lname.validate();
           }
         }

         // Step 2 form validation
         if (currentIndex === 1) {
           var email = $('#email').parsley();
           if (email.isValid()) {
             return true;
           } else {
             email.validate();
           }
         }
         // Always allow step back to the previous step even if the current step is not valid.
       } else {
         return true;
       }
     }
   });

   $('#wizard3').steps({
     headerTag: 'h3',
     bodyTag: 'section',
     autoFocus: true,
     titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
     stepsOrientation: 1
   });

   $('#wizard4').steps({
     headerTag: 'h3',
     bodyTag: 'section',
     autoFocus: true,
     titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
     cssClass: 'wizard step-equal-width'
   });

   $('#wizard5').steps({
     headerTag: 'h3',
     bodyTag: 'section',
     autoFocus: true,
     titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
     cssClass: 'wizard wizard-style-1'
   });

   $('#wizard6').steps({
     headerTag: 'h3',
     bodyTag: 'section',
     autoFocus: true,
     titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
     cssClass: 'wizard wizard-style-2'
   });

   $('#wizard7').steps({
     headerTag: 'h3',
     bodyTag: 'section',
     autoFocus: true,
     titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
     cssClass: 'wizard wizard-style-3'
   });

 });
