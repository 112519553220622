import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';
import $ from 'jquery';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css']
})
export class CouponsComponent implements OnInit {

  httpUrl = this.endpoints.couponUrl;
  pagination = 1;
  dataSource = [];
  filterCol = 'Coupon Code'
  filterSearch = '';
  loading = false;
  isLoading = false;
  toggle = false;
  buttonAttr = 'Create';
  editId;

  formData = new FormData();
  couponName;

  couponForm = new FormGroup({
    productType: new FormControl('', Validators.required),
    description: new FormControl(" "),
    discount: new FormControl('', Validators.required),
    fromDate: new FormControl(0, Validators.required),
    toDate: new FormControl(0, Validators.required),
  });

  constructor(
    private endpoints: EndpointsService,
    private genServ: GeneralService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.handleCouponsList();
  }

  private handleCouponsList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrl).subscribe((result: any) => {
      // console.log(result, 'choice');
      const { coupons } = result;
      this.dataSource = coupons;
      this.loading = false;
    });
  }

  handleNewCoupon() {
    this.isLoading = true;
    const { fromDate, toDate } = this.couponForm.value;
    let fromDateParse;
    let toDateParse
    if (fromDate && toDate) {
      fromDateParse = new Date(Date.parse((fromDate))).setHours(0, 0, 0, 0);
      toDateParse = new Date(Date.parse((toDate))).setHours(23, 59, 59, 59);
    }

    const payload = {
      ...this.couponForm.value,
      fromDate: fromDateParse,
      toDate: toDateParse
    }

    this.endpoints.create(this.httpUrl, payload).subscribe((result: any) => {
      if (result) {
        this.handleCouponsList();
        this.couponForm.reset();
        $("#newCouponModal .close").click();
        this.genServ.sweetAlertSuccess(result.message);
      }
      this.isLoading = false;
    }), error => {
      this.isLoading = false;
    };
  }

  handleEdit(data) {
    this.buttonAttr = 'Update';
    this.editId = data.id;
    this.couponName = data.couponID;
    this.couponForm.patchValue({
      productType: data.productType,
      discount: data.discount,
      description: data.description,
      fromDate: this.genServ.getDateformat(Number(data.fromDate)),
      toDate: this.genServ.getDateformat(Number(data.toDate)),
    });
    // console.log(this.getDateformat(Number(data.fromDate))) //2021-02-05
  }

  handleUpdateCoupon() {
    this.isLoading = true;
    const { fromDate, toDate } = this.couponForm.value;
    let fromDateParse;
    let toDateParse
    if (fromDate && toDate) {
      fromDateParse = new Date(Date.parse((fromDate))).setHours(0, 0, 0, 0);
      toDateParse = new Date(Date.parse((toDate))).setHours(23, 59, 59, 59);
    }

    const payload = {
      ...this.couponForm.value,
      fromDate: fromDateParse,
      toDate: toDateParse
    }

    this.endpoints.updateDetails(this.httpUrl, this.editId, payload).subscribe(
      (result: any) => {
        if (result) {
          // console.log(result, "response");
          $("#newCouponModal .close").click();
          this.couponForm.reset();
          this.genServ.sweetAlertSuccess(result.message);
          this.handleCouponsList();
          this.buttonAttr = 'Create';
          this.editId = '';
        }
        this.isLoading = false;
      },
    ), error => {
      this.isLoading = false;
    };
  }

  handleCouponDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Coupon").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
          this.handleCouponsList();
        });
      }
    })
  }

}
