import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  httpUrl = this.endpoints.orderUrl;
  httpUrlCustom = this.endpoints.customOrderUrl;
  pagination = 1;
  dataSource = [];
  filterCol = 'Fullname'
  filterSearch = '';
  loading = false;
  isLoading = false;
  editId;
  firstProductName;

  viewCustomOrderData;
  viewpage = '';
  toggle = false;

  orderForm = new FormGroup({
    status: new FormControl('', Validators.required),
    shippedStatus: new FormControl('', Validators.required),
    expectedDeliveryDate: new FormControl(''),
    totalCost: new FormControl(''),
  });

  couponDetails;


  constructor(private endpoints: EndpointsService, private genServ: GeneralService, private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe(res => {
      const { viewType } = res;
      this.toggle = viewType ? true : false;
      this.viewpage = viewType;
      !this.toggle ? this.handleOrderList() : this.handleCustomOrderList()
    })
  }

  ngOnInit() {
  }

  private handleOrderList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrl).subscribe((result: any) => {
      console.log(result, 'orders');
      const { orders } = result;
      this.dataSource = orders;
      this.loading = false;
    });
  }

  private handleCustomOrderList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrlCustom).subscribe((result: any) => {
      console.log(result, 'customArts');
      const { customArts } = result;
      this.dataSource = customArts;
      this.loading = false;
    });
  }

  handleEdit(id) {
    this.editId = id;
    let url = !this.toggle ? this.httpUrl : this.httpUrlCustom;
    this.endpoints.fetchOne(url, id).subscribe(
      (result: any) => {
        if (result) {
          if (!this.toggle) {
            const { orderFullDetails } = result;
            this.viewCustomOrderData = {
              customerName: orderFullDetails.User.fullname,
              customerEmail: orderFullDetails.User.email,
              customerMobile: orderFullDetails.User.mobile,
              customerState: orderFullDetails.User.state,
              customerCountry: orderFullDetails.User.country,
              orderBox: JSON.parse(result.orderBoxFullDetails),
              shippedAddress: orderFullDetails.shippedAddress,
              totalCost: orderFullDetails.totalCost,
              paymentStatus: orderFullDetails.paymentStatus,
            };
            this.firstProductName = this.viewCustomOrderData.orderBox[0].name;
            this.orderForm.patchValue({
              status: orderFullDetails.status,
              shippedStatus: orderFullDetails.shippedStatus,
              expectedDeliveryDate: orderFullDetails.expectedDeliveryDate ? this.genServ.getDateformat(Number(orderFullDetails.expectedDeliveryDate)) : null,
            });
            this.couponDetails = JSON.parse(result.orderFullDetails.couponDetails);
          } else {
            const { customArtsFullDetails } = result;
            // console.log(customArtsFullDetails, 'One')
            this.viewCustomOrderData = {
              customerName: customArtsFullDetails.User.fullname,
              customerEmail: customArtsFullDetails.User.email,
              customerMobile: customArtsFullDetails.User.mobile,
              customerState: customArtsFullDetails.User.state,
              customerCountry: customArtsFullDetails.User.country,
              art_price_details: JSON.parse(customArtsFullDetails.art_price_details),
              art_type: customArtsFullDetails.art_type,
              art_description: customArtsFullDetails.art_description,
              art_imgurl: customArtsFullDetails.art_imgurl,
              shippedAddress: customArtsFullDetails.shippedAddress,
              totalCost: Number(customArtsFullDetails.totalCost),
              paymentStatus: customArtsFullDetails.paymentStatus,
            };
            this.couponDetails = JSON.parse(result.customArtsFullDetails.couponDetails);
            this.orderForm.patchValue({
              status: customArtsFullDetails.status,
              shippedStatus: customArtsFullDetails.shippedStatus,
              totalCost: Number(customArtsFullDetails.totalCost),
              expectedDeliveryDate: customArtsFullDetails.expectedDeliveryDate ? this.genServ.getDateformat(Number(customArtsFullDetails.expectedDeliveryDate)) : null,
            });
          }
        }
      },
    ), error => {

    };
  }

  handleUpdateOrder() {
    this.isLoading = true;
    let url = !this.toggle ? this.httpUrl : this.httpUrlCustom;

    let { expectedDeliveryDate, totalCost } = this.orderForm.value;
    this.orderForm.value.expectedDeliveryDate = expectedDeliveryDate ?
      (new Date(Date.parse((expectedDeliveryDate))).setHours(23, 59, 59, 59)).toString() : null
    !totalCost ? delete this.orderForm.value.totalCost : null;
    this.endpoints.updateDetails(url, this.editId, this.orderForm.value).subscribe(
      (result: any) => {
        if (result) {
          $("#newOrderModal .close").click();
          console.log(result, "response");
          this.orderForm.reset();
          this.genServ.sweetAlertSuccess(result.message);
          this.editId = '';
          if (!this.toggle) {
            this.handleOrderList();
          } else {
            this.handleCustomOrderList();
          }
        }
        this.isLoading = false;
      },
    ), error => {
      this.isLoading = false;
    };
  }

  handlePaymentStatusUpdate(id) {
    this.genServ.sweetAlertUpdate("Payment Status for Order").then(result => {
      if (result.value) {
        let url = !this.toggle ? this.httpUrl : this.httpUrlCustom;
        this.endpoints.updateDetails(url, id, { paymentStatus: true }).subscribe(res => {
          if (res) {
            this.genServ.sweetAlertSuccess(res.message);
            if (!this.toggle) {
              this.handleOrderList();
            } else {
              this.handleCustomOrderList();
            }
          }
        })
      }
    });
  }

  handleDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Order").then(result => {
      if (result.value) {
        if (!this.toggle) {
          this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
            this.handleOrderList();
          });
        } else {
          this.endpoints.deleteDetails(this.httpUrlCustom, id).subscribe((result: any) => {
            this.handleCustomOrderList();
          });
        }

      }
    })
  }

  handleViewChange() {
    if (!this.toggle) {
      this.router.navigate(['/dashboard/orders'])
    } else {
      this.router.navigate(['/dashboard/orders'], {
        queryParams: { viewType: 'custom-orders' },
      });
    }
  }

  calculateTotalCostDisplay(TC, CD) {
    const couponParsed = JSON.parse(CD);
    return Number(TC - couponParsed.totalCouponCharge);
  }

  calculateItemTConView(itemName) {
    let result = 0;
    this.couponDetails.details.forEach(res => {
      let name = res.product.split('-')[1].trim();
      if (itemName.toLowerCase() === name.toLowerCase()) {
        result = res.discount;
      }
    });
    return result;
  }
}
