import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

  transform(value: any, args: any): any {
    if (args === 'modal') {
      if (value.length > 200) {
        return value.substr(0, 250) + ' ...';
      }
      return value;
    } else if (args === 'Description') {
      if (value.length > 50) {
        return value.substr(0, 50) + ' ...';
      }
      return value;

    } else if (args === 'Sizes_Prices') {
      if (value.length > 70) {
        return value.substr(0, 70) + ' ...';
      }
      return value;

    } else {
      if (value.length > 12) {
        return value.substr(0, 18) + ' ...';
      }
      return value;
    }
  }

}
