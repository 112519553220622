import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AuthcrudInterceptorService } from './auth/auth-crud-interceptor.service';

import { ArchwizardModule } from 'angular-archwizard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LaddaModule } from 'angular2-ladda';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';

import { LoginComponent } from './auth/login/login.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { AppDateAdapter, APP_DATE_FORMATS } from './shared/format-datepicker';
import { EventsComponent } from './events/events.component';
import { ProductsComponent } from './products/products.component';
import { OrdersComponent } from './orders/orders.component';
import { CustomersComponent } from './customers/customers.component';
import { HomeComponent } from './home/home.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterPipe } from './shared/filter.pipe';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ShortenPipe } from './shared/shorten.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FramesComponent } from './frames/frames.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { CouponsComponent } from './coupons/coupons.component';
import { SafePipe } from './shared/safe.pipe';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { TeamsComponent } from './teams/teams.component';
import { ArtisteComponent } from './artiste/artiste.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BannerCarouselComponent } from './banner-carousel/banner-carousel.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    EventsComponent,
    ProductsComponent,
    OrdersComponent,
    CustomersComponent,
    HomeComponent,
    FilterPipe,
    ShortenPipe,
    SafePipe,
    DashboardComponent,
    FramesComponent,
    PortfolioComponent,
    CouponsComponent,
    NewsletterComponent,
    TeamsComponent,
    ArtisteComponent,
    BannerCarouselComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ArchwizardModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatFileUploadModule,
    MatRadioModule,
    NgxPaginationModule,
    LaddaModule,
    NgxMaterialTimepickerModule,
    NgMultiSelectDropDownModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthcrudInterceptorService,
      multi: true,
    },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
