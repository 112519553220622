import { Component, OnInit } from '@angular/core';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';
import $ from 'jquery';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-frames',
  templateUrl: './frames.component.html',
  styleUrls: ['./frames.component.css']
})
export class FramesComponent implements OnInit {

  httpUrl = this.endpoints.frameUrl;
  pagination = 1;
  dataSource = [];
  filterCol = 'Name'
  filterSearch = '';
  loading = false;
  formData = new FormData();
  buttonAttr = 'Create';
  editId;
  isLoading = false;
  hidImgSpinner = true;


  frameForm = new FormGroup({
    name: new FormControl('', Validators.required),
    sizePriceFormArray: this.fb.array([]),
  });


  constructor(
    private endpoints: EndpointsService,
    private fb: FormBuilder,
    private genServ: GeneralService,
  ) { }

  ngOnInit() {
    this.handleFrameList();
  }

  get sizePriceFormArray(): FormArray {
    return this.frameForm.get("sizePriceFormArray") as FormArray;
  }


  private handleFrameList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrl).subscribe((result: any) => {
      // console.log(result.frames, 'Frame');
      const { frames } = result;
      this.dataSource = frames;
      this.loading = false;
    });
  }

  // Get Size Info
  private get getSizePriceDetails() {
    const returnSizePrice = this.sizePriceFormArray.value.map((element, i) => {
      return {
        dimension: element.dimension,
        price: element.price,
      };
    });
    return returnSizePrice;
  }

  private get handleFormData() {
    return {
      name: this.frameForm.value.name,
      sizes_prices: this.getSizePriceDetails.length > 0 ? JSON.stringify(this.getSizePriceDetails) : null
    }
  }


  handleNewFrame() {
    this.isLoading = true;
    this.genServ.sweetAlertCreate("Frame").then(response => {
      if (response.value) {
        this.endpoints.create(this.httpUrl, this.handleFormData).subscribe(
          (result: any) => {
            if (result) {
              $("#newFrameModal .close").click();
              // console.log(result, "response");
              this.frameForm.reset();
              this.genServ.sweetAlertSuccess(result.message);
              this.handleFrameList();
            }
            this.isLoading = false;
          },
        ), error => {
          this.isLoading = false;
        };
      }
    });
  }

  addSizePrice() {
    this.sizePriceFormArray.push(
      this.fb.group({
        dimension: ["", Validators.required],
        price: [0, Validators.required],
      })
    );
  }

  // Remove a form field for frame sizes
  deleteSizePrice(index: number) {
    this.sizePriceFormArray.removeAt(index);
  }

  createFormArray() {
    this.sizePriceFormArray.push(
      this.fb.group({
        dimension: ["", Validators.required],
        price: [0, Validators.required],
      })
    );
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }


  handleEdit(data) {
    this.clearFormArray(this.sizePriceFormArray)

    this.hidImgSpinner = false;
    this.buttonAttr = 'Update';
    this.editId = data.id;
    this.frameForm.patchValue({
      name: data.name,
    });

    const sizes_prices = JSON.parse(data.sizes_prices);
    // console.log(sizes_prices, 'eod')
    setTimeout(() => {
      sizes_prices.forEach((element) => {
        this.sizePriceFormArray.push(
          this.fb.group({
            dimension: [element.dimension, Validators.required],
            price: [element.price, Validators.required],
          })
        );
      });
    }, 2000);
  }


  handleUpdateFrame() {
    this.isLoading = true;
    this.endpoints.updateDetails(this.httpUrl, this.editId, this.handleFormData).subscribe(
      (result: any) => {
        if (result) {
          $("#newFrameModal .close").click();
          // console.log(result, "response");
          this.frameForm.reset();
          this.genServ.sweetAlertSuccess(result.message);
          this.handleFrameList();
          this.buttonAttr = 'Create';
          this.editId = '';
        }
        this.isLoading = false;
      },
    ), error => {
      this.isLoading = false;
    };

  }

  handleDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Frame").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
          this.handleFrameList();
        });
      }
    })
  }


}
