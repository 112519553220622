import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { LocalStorageService } from '../utils/LocalStorage';
import { Router } from '@angular/router';
import { TokenVerification } from '../utils/TokenVerification';
import { EndpointsService } from '../shared/endpoints.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;

  constructor(
    private localstorage: LocalStorageService,
    private token: TokenVerification,
    private router: Router,
    private endpoints: EndpointsService
  ) { }

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  login() {
    const token = JSON.parse(
      this.localstorage.getFromLocalStorage('AdminUsertoken')
    );
    const status = this.token.verifyToken(token);
    console.log(status, '1019');
    this.endpoints.err.subscribe(res => res === 'User Not Admin' ? this.logout() : null);
    status ? (this.isLoggedIn = true) : (this.isLoggedIn = false);
    if (!status) {
      this.localstorage.deleteFromLocalStorage('AdminUserInfo');
      this.localstorage.deleteFromLocalStorage('AdminUsertoken');
    }
    return status;
  }

  logout(): void {
    this.localstorage.deleteFromLocalStorage('AdminUserInfo');
    this.localstorage.deleteFromLocalStorage('AdminUsertoken');
    this.isLoggedIn = false;
    this.router.navigate(['/login']);
  }
}
