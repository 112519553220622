import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any, filterColumn: any, filterSearch: any): any {
    if (!filterSearch || filterSearch.length === 0) {
      return items;
    }
    if (!filterColumn || (filterColumn && !filterSearch)) {
      return items;
    }

    // Products
    if (filterColumn === 'Name') {
      return items.filter(item => {
        return item.name.toLowerCase().includes(filterSearch.toLowerCase());
      });
    } if (filterColumn === 'SKU') {
      return items.filter(item => {
        return item.sku.includes(filterSearch);
      });
    } if (filterColumn === 'Category') {
      return items.filter(item => {
        return item.category.toLowerCase().includes(filterSearch.toLowerCase());
      });
    } if (filterColumn === 'Price') {
      return items.filter(item => {
        return item.price.includes(filterSearch);
      });
    }

    // Products End

    // Coupons
    if (filterColumn === 'Coupon Code') {
      return items.filter(item => {
        return item.couponID.toLowerCase().includes(filterSearch.toLowerCase());
      });
    }
    // Coupons End

    // Events
    if (filterColumn === 'Fullname') {
      return items.filter(item => {
        if (filterSearch !== 'na') {
          if (item.fullname) {
           return item.fullname.toLowerCase().includes(filterSearch.toLowerCase());
          }
        } else if (filterSearch == 'na') {
          return !item.fullname;
        }
      });
    }
    if (filterColumn === 'Town') {
      return items.filter(item => {
        return item.town.toLowerCase().includes(filterSearch.toLowerCase());
      });
    }
    if (filterColumn === 'Domain') {
      return items.filter(item => {
        if (filterSearch !== 'na') {
          if (item.domain) {
            return item.domain.toLowerCase().includes(filterSearch.toLowerCase());
          }
        } else if (filterSearch == 'na') {
          return !item.domain;
        }
      });
    }
    if (filterColumn === 'Email') {
      return items.filter(item => {
        return item.email.toLowerCase().includes(filterSearch.toLowerCase());
      });
    }
    if (filterColumn === 'Event' || filterColumn === "Event-Name") {
      return items.filter(item => {
        return filterColumn === 'Event' ? item.event.toLowerCase().includes(filterSearch.toLowerCase()) :
          item.name.toLowerCase().includes(filterSearch.toLowerCase());
      });
    }
    if (filterColumn === 'Date') {
      return items.filter(item => {
        return item.createdAt.slice(0, 10).includes(
          filterSearch.toString()
        );
      });
    }
  }
}
