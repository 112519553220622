import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from './home/home.component';
import { CustomersComponent } from './customers/customers.component';
import { OrdersComponent } from './orders/orders.component';
import { EventsComponent } from './events/events.component';
import { ProductsComponent } from './products/products.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FramesComponent } from './frames/frames.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { CouponsComponent } from './coupons/coupons.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { TeamsComponent } from './teams/teams.component';
import { ArtisteComponent } from './artiste/artiste.component';
import { BannerCarouselComponent } from './banner-carousel/banner-carousel.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'admin-selection',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard', component: DashboardComponent, children: [
      { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard], },
      { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], },
      { path: 'products', component: ProductsComponent, canActivate: [AuthGuard], },
      { path: 'frames', component: FramesComponent, canActivate: [AuthGuard], },
      { path: 'events', component: EventsComponent, canActivate: [AuthGuard], },
      { path: 'portfolio', component: PortfolioComponent, canActivate: [AuthGuard], },
      { path: 'coupons', component: CouponsComponent, canActivate: [AuthGuard], },
      { path: 'newsletter', component: NewsletterComponent, canActivate: [AuthGuard], },
      { path: 'team', component: TeamsComponent, canActivate: [AuthGuard], },
      { path: 'artiste', component: ArtisteComponent, canActivate: [AuthGuard], },
      { path: 'banner', component: BannerCarouselComponent, canActivate: [AuthGuard], },
    ]
  },


  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
