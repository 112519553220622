import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';
import $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
import { exportTableToCSV } from '../utils/utils';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  httpUrl = this.endpoints.eventUrl;
  httpUrlUser = this.endpoints.eventUsersUrl;
  pagination = 1;
  dataSourceEventUsers = [];
  dataSource = [];
  filterCol = 'Event-Name'
  filterSearch = '';
  loading = false;
  isLoading = false;
  toggle = false;
  buttonAttr = 'Create';
  editId;


  imgURL;
  hidImgSpinner = true;
  imageSizeMaxed = false;
  formData = new FormData();
  viewpage = '';

  eventForm = new FormGroup({
    name: new FormControl('', Validators.required),
    quota: new FormControl('', Validators.required),
    description: new FormControl(" ", Validators.required),
    venue: new FormControl(" ", Validators.required),
    fromDate: new FormControl(0, Validators.required),
    toDate: new FormControl(0, Validators.required),
    time: new FormControl(0, Validators.required),
    gateFee: new FormControl(0, Validators.required),
  });

  constructor(
    private endpoints: EndpointsService,
    private genServ: GeneralService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe(res => {
      const { viewType } = res;
      this.filterCol = viewType ? 'Fullname' : 'Event-Name';
      this.toggle = viewType ? true : false;
      this.viewpage = viewType;
    })
  }

  ngOnInit() {
    this.handleEventUsersList();
    this.handleEventsList();
  }

  private handleEventsList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrl).subscribe((result: any) => {
      // console.log(result, 'choice');
      const { events } = result;
      this.dataSource = events;
      this.loading = false;
    });
  }

  private handleEventUsersList() {
    this.loading = true;
    this.endpoints.fetchAll(this.httpUrlUser).subscribe((result: any) => {
      // console.log(result.eventUsers, 'choice');
      const { eventUsers } = result;
      this.dataSourceEventUsers = eventUsers;
      this.loading = false;
    });
  }

  private handleFormDataAppend() {
    const { name, quota, description, venue, fromDate, toDate, time, gateFee } = this.eventForm.value;
    let fromDateParse;
    let toDateParse
    if (fromDate && toDate) {
      fromDateParse = new Date(Date.parse((fromDate))).setHours(0, 0, 0, 0);
      toDateParse = new Date(Date.parse((toDate))).setHours(23, 59, 59, 59);
    }

    name ? this.formData.set("name", name) : null;
    quota ? this.formData.set("quota", quota) : null;
    description ? this.formData.set("description", description) : null;
    venue ? this.formData.set("venue", venue) : null;
    fromDateParse ? this.formData.set("fromDate", fromDateParse) : null;
    toDateParse ? this.formData.set("toDate", toDateParse) : null;
    time ? this.formData.set("time", time) : null;
    gateFee ? this.formData.set("gateFee", gateFee) : null;
  }

  private filterDatasource(data): any {
    if (this.filterCol === 'Event') {
      return data.filter(item =>
          item.event.toLowerCase().includes(this.filterSearch.toLowerCase())
      );
    } else if(this.filterCol === 'Date') {
      return data.filter(item => {
        return item.createdAt.slice(0, 10).includes(
          this.filterSearch.toString()
        );
      });
    } else if (this.filterCol === 'Fullname') {
      return data.filter(item => {
        if (this.filterSearch !== 'na') {
          if (item.fullname) {
            return item.fullname.toLowerCase().includes(this.filterSearch.toLowerCase());
          }
        } else if (this.filterSearch == 'na') {
          return !item.fullname;
        }
      });
    }
    return data;
  }

  handleCSV(data3: any) {
    const data = this.filterDatasource(data3);
    const exportName = `${this.filterCol}Users - ${this.filterSearch || 'All'}`;
    const columns = [
      { title: "Client ID", value: "id" },
      { title: "Fullname", value: "fullname" },
      { title: "Email", value: "email" },
      { title: "Mobile", value: "mobile" },
      { title: "Event", value: "event" },
      { title: "TicketNo", value: "ticketNo" },
      { title: "No of Guest", value: "guest" },
      { title: "TicketNo", value: "ticketNo" },
      { title: "Creation Date", value: "createdAt" },
    ];
    exportTableToCSV(data, columns, exportName);
  }


  handleViewChange() {
    !this.toggle ? this.router.navigate(['/dashboard/events']) : this.router.navigate(['/dashboard/events'], {
      queryParams: { viewType: 'users' },
    })
  }

  handleNewEvent() {
    this.isLoading = true;
    this.handleFormDataAppend();
    this.endpoints.create(this.httpUrl, this.formData).subscribe((result: any) => {
      if (result) {
        this.handleEventsList();
        this.eventForm.reset();
        $("#newEventModal .close").click();
        this.genServ.sweetAlertSuccess(result.message);
      }
      this.isLoading = false;
    }), error => {
      this.isLoading = false;
    };
  }

  onFileUpload(e) {
    const files = <File>e.target.files[0];
    this.formData.set("img", files);
    // console.log(files, 'files');
    this.imageSizeMaxed = files.size > 800000;
    var reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      // console.log(this.imgURL, 'localurj');
    };
  }

  handleEdit(data) {
    this.hidImgSpinner = false;
    this.buttonAttr = 'Update';
    this.editId = data.id;
    this.formData.delete('img');
    this.eventForm.patchValue({
      name: data.name,
      quota: data.quota,
      description: data.description,
      venue: data.venue,
      imgUrl: data.imgUrl,
      fromDate: this.genServ.getDateformat(Number(data.fromDate)),
      toDate: this.genServ.getDateformat(Number(data.toDate)),
      time: data.time,
      gateFee: data.gateFee,
    });
    // console.log(this.getDateformat(Number(data.fromDate))) //2021-02-05

    this.imgURL = `https://api.kokopelliafrica.com/kokopelli-backend/uploads/events/${data.imgUrl}`;
    setTimeout(() => {
      this.hidImgSpinner = true;
    }, 4000);
  }

  handleUpdateEvent() {
    this.isLoading = true;
    this.handleFormDataAppend();
    this.endpoints.updateDetails(this.httpUrl, this.editId, this.formData).subscribe(
      (result: any) => {
        if (result) {
          // console.log(result, "response");
          $("#newEventModal .close").click();
          this.eventForm.reset();
          this.genServ.sweetAlertSuccess(result.message);
          this.handleEventsList();
          this.buttonAttr = 'Create';
          this.editId = '';
        }
        this.isLoading = false;
      },
    ), error => {
      this.isLoading = false;
    };
  }

  handleEventDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Event").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
          this.handleEventsList();
        });
      }
    })
  }

  handleEventUserDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Event User").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrlUser, id).subscribe((result: any) => {
          this.handleEventUsersList();
        });
      }
    })
  }

}
