import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EndpointsService } from '../shared/endpoints.service';
import { GeneralService } from '../shared/general.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  httpUrl = this.endpoints.newsletterUrl;
  pagination = 1;
  dataSource = [];
  loading = false;
  toggle = false;
  view = "kokopelli"

  constructor(private endpoints: EndpointsService, private genServ: GeneralService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe(res => {
      const { viewType } = res;
      if (viewType === 'vogoandnovo') {
        this.toggle = true;
        this.view = viewType;
      } else {
        this.toggle = false;
        this.view = 'kokopelli';
      }

      // this.toggle = false;
      this.handleNewsletterList()
    })
  }

  ngOnInit() {

  }
  handleNewsletterList() {
    this.loading = true;
    this.endpoints.fetchOne(this.httpUrl, this.view).subscribe((result: any) => {
      const { newsletter } = result;
      this.dataSource = newsletter;
      this.loading = false;
    });
  }

  handleViewChange() {
    !this.toggle ? this.router.navigate(['/dashboard/newsletter'], {
      queryParams: { viewType: 'kokopelli' },
    }) : this.router.navigate(['/dashboard/newsletter'], {
      queryParams: { viewType: 'vogoandnovo' },
    })
  }

  handleSubscriptionDeletion(id) {
    this.genServ.sweetAlertFileDeletions("Newsletter").then(result => {
      if (result.value) {
        this.endpoints.deleteDetails(this.httpUrl, id).subscribe((result: any) => {
          this.handleNewsletterList();
        });
      }
    })
  }

}
