import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  signupValidator = inputObject => {
    const errors: any = {};
    if (!inputObject.email || !inputObject.username || !inputObject.password) {
      errors.email = !inputObject.email ? 'Email Required' : undefined;
      errors.username = !inputObject.username ? 'Username Required' : undefined;
      errors.password = !inputObject.password ? 'Password Required' : undefined;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputObject.email)
    ) {
      errors.email = 'Invalid email address';
    } else if (!inputObject.email.includes('@oldmutualnigeria.com')) {
      errors.email = 'Email must have the oldmutual domain';
    } else if (inputObject.password.length < 6) {
      errors.password = 'Password is weak';
    } else if (inputObject.password !== inputObject.confirmPassword) {
      errors.confirmPassword = 'Passwords don\'t match';
    }
    return errors;
  }

  loginValidator = inputObject => {
    const errors: any = {};
    if (!inputObject.username || !inputObject.password) {
      errors.username = !inputObject.username
        ? 'Username Required'
        : undefined;
      errors.password = !inputObject.password ? 'Password Required' : undefined;
    }
    return errors;
  }

}
